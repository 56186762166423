'use client'
import type { FC, ReactNode } from 'react'
import dynamic from 'next/dynamic'
import { ThemeProvider } from 'styled-components'
import StyledComponentsRegistry from '@/utils/styled-components-registry'
import { TranslationsProvider, ModalsProvider, ToastProvider } from '@/context'
import { theme } from '@/styles'
import { LoadingScreen } from './LoadingScreen'

const DynamicModalMenu = dynamic(() => import('@/components/modals/ModalMenu').then(mod => mod.ModalMenu), { ssr: false })

type Props = {
  children: ReactNode
}

const ClientProviders:FC<Props> = ({ children }) => {
  return (
    <StyledComponentsRegistry>
      <ThemeProvider theme={theme}>
        <TranslationsProvider>
          <ModalsProvider>
            <ToastProvider>
              {children}
              <DynamicModalMenu />
              <LoadingScreen/>
            </ToastProvider>
          </ModalsProvider>
        </TranslationsProvider>
      </ThemeProvider>
    </StyledComponentsRegistry>
  )
}

export default ClientProviders
