'use client'
import styled, { css } from 'styled-components'
import { mq, vw, font170_153, font62_55, font40_44, font32_35, font13_15, font15_18, font18_18, font20_22 } from '@/styles'
// import { useTranslator } from '@/hooks'

export const Footer = ({  }) => {
  // const { t } = useTranslator()

  return (
    <FooterStyled>
      <p>Recupera tu <br />ritmo natural</p>
      <aside>
        <h4>Duerme mejor, siéntete mejor</h4>
        <a href='https://www.silversanz.com/es/categoria/3/subcategoria/132/productos'>¡Lo quiero!</a>
        <ul>
          <li><a href='https://silversanz.com/'>SkyPortal es un producto de Silversanz</a></li>
          <li><a href='https://silversanz.com/pagina/ayuda-y-contacto'>Ayuda y contacto</a></li>
          <li><a href='https://silversanz.com/pagina/politica-de-privacidad'>Política de privacidad</a></li>
          <li><a href='https://silversanz.com/pagina/terminos-y-condiciones-b2c'>Términos y condiciones</a></li>
        </ul>
      </aside>
    </FooterStyled>
  )
}

export const CSSButton = () => css`
  ${font13_15(true, 500)}
  align-items: center;
  background-color: var(--color-bright-blue);
  border-radius: ${vw(30, 'mobile')};
  color: var(--color-white);
  display: flex;
  height: ${vw(35, 'mobile')};
  margin: 0 auto;
  justify-content: center;
  letter-spacing: -.52px;
  padding: 0 ${vw(20, 'mobile')};
  transition: 250ms background-color linear, 300ms color linear;
  white-space: nowrap;
  width: auto;
  width: fit-content;

  ${mq.greaterThan('nexus7')} {
    border-radius: ${vw(30, 'tablet')};
    height: ${vw(35, 'tablet')};
    padding: 0 ${vw(20, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    ${font15_18(false, 500)}
    border-radius: ${vw(30, 'desktop')};
    height: ${vw(39, 'desktop')};
    letter-spacing: -.6px;
    padding: 0 ${vw(20, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    border-radius: 30px;
    height: 39px;
    padding: 0 20px;
  }

  &:hover {
    background-color: var(--color-dark-blue);
    color: var(--color-white);
  }
`


const FooterStyled = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${vw(30, 'mobile')} ${vw(25, 'mobile')};
  width: 100%;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(30, 'tablet')} ${vw(25, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(60, 'desktop')} 0 ${vw(20, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding: 60px 0 20px;
  }

  p,
  h4 {
    color: var(--color-dark-blue);
    margin-bottom: ${vw(20, 'mobile')};
    text-align: center;

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(40, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 40px;
    }
  }

  p {
    ${font62_55(true, 500)}
    letter-spacing: -2.48px;

    ${mq.greaterThan('tablet')} {
      ${font170_153(false, 500)}
      letter-spacing: -6.8px;
    }
  }

  h4 {
    ${font32_35(true, 500)}
    letter-spacing: -1.28px;

    ${mq.greaterThan('tablet')} {
      ${font40_44(false, 500)}
      letter-spacing: -1.6px;
    }
  }

  aside {
    > a {
      ${CSSButton()}
      ${font18_18(true, 500)}
      letter-spacing: .4px;
      height: auto;
      margin-bottom: ${vw(120, 'mobile')};
      padding: ${vw(10, 'mobile')} ${vw(20, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-bottom: ${vw(120, 'tablet')};
        padding: ${vw(10, 'tablet')} ${vw(20, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        ${font20_22(true, 500)}
        letter-spacing: .8px;
        margin-bottom: ${vw(120, 'desktop')};
        padding: ${vw(10, 'desktop')} ${vw(20, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 120px;
        padding: 10px 20px;
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    width: 100%;

    li {
      margin: 0 ${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
       margin: 0 ${vw(10, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        margin: 0 ${vw(60, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin: 0 60px;
      }

      a {
        ${font13_15(true, 500)}
        color: var(--color-dark-blue);
        letter-spacing: -0.52px;
        text-decoration: none;

        ${mq.greaterThan('tablet')} {
          ${font15_18(false, 500)}
          letter-spacing: -0.2px;
        }

        ${mq.greaterThan('desktop-md')} {
          ${font13_15(false, 500)}
          letter-spacing: -0.6px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`
