'use client'
import { type FC, useMemo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { MODALS } from '@/context'
import { useMount, useModal, useScrollTracker, useDeviceType} from '@/hooks'
import { mq, vw, designGrid, font15_18 } from '@/styles'
import { iconByName } from '@/utils'
import { Figure, Link } from '@/components'
import { CSSButton } from '@/components/layout/Footer'

const mediaLogo = {
  type: 'svg' as const,
  src: '/images/svg/logo--skyportal.svg',
  alt: 'SkyPortal logo',
  size: { width: 141, height: 27 }
}

const getLinks = (isMobile: boolean) => {
  if(isMobile) return []

  return [
    <Li key={1} className='d-link'><Link href='/#gallery'>Galería</Link></Li>,
    <Li key={2} className='d-link'><Link href='/#modelos'>Modelos</Link></Li>,
    <Li key={3} className='d-link'><Link href='/#app'>App/Smarthome</Link></Li>
  ]
}

export const Nav:FC = () => {
  const scrollState = useScrollTracker(70)
  const { isActive:isMenuActive, toggle:toggleMenu } = useModal(MODALS.MENU)
  const isMount  = useMount()
  const { isMobile } = useDeviceType()
  const Links = useMemo(() => getLinks(isMobile), [isMobile])

  return isMount &&(
    <NavStyled $scrollTop={scrollState === 'TOP'} $bgTransparent={false} $secondaryBar={false} $menuActive={isMenuActive} initial={{ y: -60 }} animate={{ y: 0 }} transition={{ delay: 0, duration: .5, type: 'spring', damping: 10, stiffness: 50 }}>
      <ul>
        <Li key='nav-logo'>
          <Link href='/' className='logo'>
            <Figure media={mediaLogo} lazy={false} fill={false}/>
          </Link>
        </Li>
        {Links}
        <Li className='li--menu'>
          <button onClick={toggleMenu} name='Menu'><Figure className={'menu'} media={iconByName(isMenuActive ? 'close' : 'menu')} lazy={false} /></button>
        </Li>
        <Li className='li--cta'>
          <a href='https://www.silversanz.com/es/categoria/3/subcategoria/132/productos'>¡Lo quiero!</a>
        </Li>
      </ul>
    </NavStyled>
  )
}

const NavStyled = styled(motion.nav) <{ $scrollTop: boolean, $bgTransparent: boolean, $secondaryBar: boolean, $menuActive: boolean }>`
  ${designGrid({})}
  height: 80px;
  position: absolute;
  top: 0;
  transform: translate3d(0, 0, 0);
  width: 100vw;
  width: 100dvw;
  z-index: 1000;


  ${mq.greaterThan('tablet')} {
    margin: 0 auto;
  }

  > ul {
    background-color: var(--color-white);
    align-self: center;
    align-items: center;
    border-radius: 30px;
    display: flex;
    grid-column: 1 / span 6;
    height: 57px;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 15px;
    position: relative;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      grid-column: 2 / span 10;
    }

    ${mq.greaterThan('desktop')} {
      max-width: 1440px;
    }
  }

  a {
    color: var(--color-dark-blue);
    display: block;
    transition: 300ms color ease-in-out, 300ms opacity ease-in-out;

    ${mq.greaterThan('tablet')} {
      /* ${font15_18(false, 500)} */
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -.6px;
    }

  }

  .logo {
    display: flex;
    height: 27px;
    width: 141px;

    figure {
      width: 100%;
      z-index: 2;

      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }
  
  .cta {
    color: var(--color-bright-blue);
  }
`

const Li = styled.li<any>`
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    ${mq.greaterThan('tablet')} {
      margin: 0 ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin: 0 20px;
    }
  }

  &:nth-child(2) {
    ${mq.greaterThan('tablet')} {
      margin-left: auto;
    }
  }

  &:nth-child(4) {
    ${mq.greaterThan('tablet')} {
      margin-right: auto;
    }
  }

  &.li--menu {
    margin-left: auto;

    ${mq.greaterThan('tablet')} {
      display: none;
    }

    button {
      align-items: center;
      background: var(--color-white);
      border-radius: 30px;
      display: flex;
      height: 57px;
      justify-content: center;
      padding: 15px;
      position: fixed;
      right: ${vw(15, 'mobile')};
      top: 11.5px;
      width: 57px;
    }

    figure {
      height: 15px;
      width: 30px;
    }
  }

  &.d-link {
    ${mq.lessThan('tablet')} {
      display: none;
    }
  }

  &.li--cta {
    ${mq.lessThan('tablet')} {
      display: none;
    }

    a {
      ${CSSButton()}
    }
  }
`



